/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.ngx-datatable .datatable-header {
    display: block;
    overflow: hidden;
    background-color: #011a40;
    color: #fff;
    padding: 8px 8px;
}
.ngx-datatable .datatable-body .datatable-body-row:hover{background-color: #beebfa;}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell, .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
    display: flex;
    align-items: center;
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
    background-color: hsla(0,0%,62%,.2);
    font-weight: 700;
}
.ngx-datatable .datatable-footer .datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li {
    padding: 0;
    margin: 0;
    display: inline-block;
    list-style: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
    cursor: pointer;
    display: inline-block;
}
.ngx-datatable.material .datatable-footer {
    border-top: 1px solid rgba(0,0,0,.12);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0,0,0,.54);
}

.ngx-datatable *, .ngx-datatable :after, .ngx-datatable :before {
    box-sizing: border-box;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
    vertical-align: middle;
    padding: 0;
    margin: 0;
    display: inline-block;
    list-style: none;
}
 
.ngx-datatable *, .ngx-datatable :after, .ngx-datatable :before {
    box-sizing: border-box;
}
.ngx-datatable.material .datatable-footer {
    border-top: 1px solid rgba(0,0,0,.12);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0,0,0,.54);
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 24px;
    line-height: 22px;
    padding: 0 6px;
    border-radius: 3px;
    margin: 6px 3px;
    text-align: center;
    vertical-align: top;
    color: rgba(0,0,0,.54);
    text-decoration: none;
    vertical-align: bottom;
}
.alert.alert-mtop{margin-top: 0;}
.alert{margin-bottom: 0;}

datatable-body-cell.datatable-body-cell.sort-active.ng-star-inserted {
    padding-left: 8px;
}
.prg-ratio.session{
    width: calc(100% - 50px);
}
video:focus{outline: none;}
.card-profile-header a.ico-profile-upload{right:140px;}

.crop-header{border-bottom:1px solid #ddd;}
.crop-container{width: 900px;margin:50px auto 0;background-color: #fff;padding: 10px;border-radius: 5px;overflow: hidden;}
.crop-body{min-height: 300px;}
.crop-footer{padding:5px 0; border-top:1px solid #ddd;}
.crop-footer .btn-primary {
    background-color: #0e226b;
    padding: 5px 20px;
    font-size: 14px;
    letter-spacing: 0;
    border: 0;
}

.timepicker-overlay{
    z-index: 1050 !important;
}


element.style {
    border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid;
    color: #123423;
}
/* .fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid !important;
    color: #123423 !important;
} */
.canvas-container{position: relative;height: 400px;width:100%;}
.canvas-container .drawing1,.canvas-container .drawing2{position: absolute;top: 0;left:0;}
.canvas-container .drawing1{position: absolute;top: 0;left:0;z-index: 10;}
.canvas-container .drawing2{position: absolute;top: 0;left:0;z-index: 9;}
.video-analyse-page .vprofile-video{position: relative;}
.canvas-parent{position:absolute;top: 0;left: 0;}

 
.custom-seekbar, .custom-seekbar span{height:5px;}
.custom-seekbar span{-webkit-border-radius:5px; -moz-border-radius:5px; border-radius:5px;}
.custom-seekbar{cursor:pointer;}
.custom-seekbar{background-color:#ddd;position:relative;}
.custom-seekbar span{top:0;}
.custom-seekbar span{left:0;}

.custom-seekbar span{height:5px;}
.custom-seekbar span{background-color:#0667d6;position:absolute;}
span.skill-type{margin-left: 5px;}
.mapping-container .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell, .mapping-container .ngx-datatable .datatable-header-cell {
    overflow: visible !important;
}
image-cropper .overlay {
    background-color: transparent;
}
.step-item {
    padding: 10px 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
}
.step-item a.clear-list {
    position: absolute;
    right: -5px;
    top: -12px;
}


a.cls-popup.ng-pristine.ng-valid.select{
    background-color:#64b4e6;
}
.se-shots a.select {
    background-color: #64b4e6; 
    color: #011a40;
}
.se-bowls a.select {
    background-color: #64b4e6; 
    color: #011a40;
}

.datatable-body-cell .status{border:2px solid #64b4e6;padding:2px 5px;border-radius:20px;cursor:pointer;background-color:#fff; min-width: 100px; text-align: center;}
.datatable-body-cell .status.completed{border: 2px solid #64b4e6;background-color: #beebfa; min-width: 100px; text-align: center;}
.video-tag-tbl .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell:last-child, .video-tag-tbl .ngx-datatable .datatable-header .datatable-header-cell:last-child{width:auto !important}
.bowl-specification-radio .mat-radio-button {margin-bottom: 12px;}
.vpsession-name {
    font-size: 13px;
    margin-bottom: 4px;
}

.analyse-list.mb15.ng-star-inserted.selected {
    border: 1px solid #00193c; 
    border-left: 5px solid #00193c;
}
.video-analyse-page .vprofile-video.video-portrait {
    height: 450px;
    background-color: #000;
}
.video-progress-bar:after {
    position: absolute;
    right: -9px;
    content: '';
    width: 18px;
    height: 18px;
    background-color: #01193c;
    bottom: -4px;
    z-index: 0;
    border-radius: 50%;
    border: 2px solid rgba(255,255,255,.5);
}
.dropdown-menu.se-popup-container.shot-ball-type {
    height: 200px;
    overflow-y: auto;
}
.reviewedby .nameContainer{display: inline-block;}
.reviewedimg{vertical-align:top !important;}

.feed-info .vprofile-feed{text-align:right;padding:0;} 
.feed-info .vprofile-feed a{vertical-align:bottom;}

.customBtn .feed-info{vertical-align:top;}
.customBtn .feed-info .vprofile-feed{vertical-align: top;}
.customBtn .session{vertical-align: bottom;}